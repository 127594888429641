import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import Features from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import MainFeature from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/ThreeColSimple.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
// import FeatureStats from "components/features/TwoColSingleFeatureWithStats.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";

const HighlightedText = tw.span`text-primary-500`

export default () => {
  return (
    <AnimationRevealPage>
      <FeatureStats/>
      {/* <Features 
        heading={<>Amazing <HighlightedText>Features</HighlightedText></>}
      /> */}
      <MainFeature
        heading={<>Our <HighlightedText>Services</HighlightedText></>}
      />
      {/* <Testimonial 
        heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
      /> */}
      <ContactUsForm />
      {/* <TwoColContactUsWithIllustrationFullForm /> */}

      <Footer />
    </AnimationRevealPage>
  );
}
