import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ThankYou from "components/forms/ThankYou.js";
import MainHeader from "components/headers/MainHeader";

export default () => {
  return (
    <>
        <MainHeader />
        <AnimationRevealPage>
            <ThankYou />
            <Footer />
        </AnimationRevealPage>
    </>
  );
};
