import React from "react";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import HeaderBase, { NavLinks, NavLink, NavLinkPhone } from "components/headers/light.js";

// const HeaderWrapper = tw.div`fixed bg-white z-50 w-screen p-4 -m-8 md:p-6 lg:p-6`;
const HeaderWrapper = tw.div`fixed m-0 bg-white z-50 w-screen p-4 md:p-6 lg:p-6`;
const Header = tw(HeaderBase)`max-w-none text-black`;

const navLinks = [
  <NavLinks key={1}>
    <Link to="/gallery">
      <NavLink href="/#">Gallery</NavLink>
    </Link>
    <Link to="/contact-us">
      <NavLink href="/#">Contact Us</NavLink>
    </Link>
    <Link to="/pricing">
      <NavLink href="/#">Pricing</NavLink>
    </Link>
  </NavLinks>,
  <NavLinks key={2}>
    <NavLinkPhone href="tel:3364376394">336-437-6394</NavLinkPhone>
  </NavLinks>
];

export default () => {
  return (
    <HeaderWrapper>
        <Header links={navLinks} />
    </HeaderWrapper>
  );
};
