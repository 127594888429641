import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from "./App";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactUs";
import ThankYou from "pages/ThankYou";
import Gallery from "pages/Gallery";
import Modal from "react-modal";

import Landing from "sites/Landing";
import Landing2 from "sites/Landing2";

import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";

// Modal.setAppElement("#root");

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Route exact path="/about-us"><AboutUs /></Route> */}
      <Route exact path="/thank-you"><ThankYou /></Route>
      <Route exact path="/thank-you/"><ThankYou /></Route>
      <Route exact path="/contact-us"><ContactUs /></Route>
      <Route exact path="/contact-us/"><ContactUs /></Route>
      <Route exact path="/pricing"><ContactUs /></Route>
      <Route exact path="/pricing/"><ContactUs /></Route>
      <Route exact path="/gallery"><Gallery /></Route>
      <Route exact path="/gallery/"><Gallery /></Route>
      <Route exact path="/"><Landing /></Route>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
)