import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import MainHeader from "components/headers/MainHeader";

export default () => {
  return (
    <>
        <MainHeader />
        <AnimationRevealPage>
            <ContactUsForm />
            <Footer />
        </AnimationRevealPage>
    </>
  );
};
