import emailjs from '@emailjs/browser';

export default function sendMail(currentForm) {
    emailjs.sendForm('service_k5xs7w2', 'template_ev3gkgh', currentForm, 'F8pqkDFaP5CPaiRf5')
    .then(function(response) {
        console.log('SUCCESS')
    }, function(error) {
        console.log('EMAIL FAILED...');
    });

    return true;
}
