import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const Images = importAll(require.context('../../images/gallery', false, /\.(jpe?g)$/));

// console.log('IMAGES RAW: ', Images);

const keys = Object.keys(Images);
console.log('KEYS: ', keys);

// const images = Object.keys(Images).map((file) => ({
//   src: assets(`./${file}`).default
// }));

const one = require(`../../images/gallery/${keys[0]}`).default;
console.log('ONE: ', one);
const two = require(`../../images/gallery/${keys[1]}`).default;
console.log('TWO: ', two);

const images = keys.map((file) => {
  const img = {
    src: require(`../../images/gallery/${file}`).default
  }
  return img;
});

console.log("IMAGES: ", images);

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)`mb-4`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
export default () => {
  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Gallery</Heading>
        </HeadingWithControl>
        <Carousel transitionSpeed="2000" images={images} style={{height: 1000}} />
      </Content>
    </Container>
  );
};
