import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, { NavLinks, NavLink, NavLinkPhone } from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { Link } from "react-router-dom";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as QuotesLeftIconBase } from "images/quotes-l.svg"
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg"
import StaircaseImage from "../../images/feature/stairs.jpg";
import DoorsImage from "../../images/feature/doors-2.jpg";
import MainHeader from "components/headers/MainHeader";

const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(SectionHeading)`text-left text-primary-900 leading-snug xl:text-6xl`;
const Description = tw(SectionDescription)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative transform xl:-translate-x-24 xl:-translate-y-16`;
const InnerImage1 = tw.div`absolute z-20 transform md:translate-x-1/4 md:-translate-y-2/3 xl:translate-x-1/2 xl:-translate-y-2/3 hidden xl:block`;
const Image = tw.img`z-30 max-w-full w-96 rounded-t sm:rounded relative`;
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;
const Testimonial = tw.div`bottom-auto max-w-sm rounded-b md:rounded-none relative sm:absolute inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-primary-900 text-gray-400 font-medium transform md:-translate-x-32 -translate-y-24 text-sm leading-relaxed md:-mr-16 xl:mr-0`
const QuotesLeftIcon = tw(QuotesLeftIconBase)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 md:text-red-500 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`
const Quote = tw.blockquote``
const CustomerName = tw.p`mt-4 font-bold`
const CustomerCompany = tw.p`mt-1 text-sm text-gray-500`


export default ({
  heading = "We know glass.",
  description = "At Bihn Brothers Glass, we provide custom shower installations, custom mirrors, custom cabinetry and more! As a family-owned business, we pride ourselves on offering our customers the best project recommendations, provide exceptional customer service, and ensure consistent reliability. Check out our photo albums to see what we can do for you.",
  // imageSrc = "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
  imageSrc = StaircaseImage,
  imageSrc2 = DoorsImage,
  imageDecoratorBlob = true,
  primaryButtonUrl = "/gallery",
  primaryButtonText = "Gallery",
  buttonRounded = true,
  features = ["100% Satisfaction Guarantee", "24/7 Support"],
  testimonial = {
    quote: "I sure do love Bihn Brothers Glass! They are just fantastic.",
    customerName: "Charlotte Hale",
    customerCompany: "FakeCompany Inc."
  }
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const navLinks = [
    <NavLinks key={1}>
      <Link to="/about-us">
        <NavLink href="/#">About</NavLink>
      </Link>
      <Link to="/contact-us">
        <NavLink href="/#">Contact Us</NavLink>
      </Link>
      <NavLink href="/#">Pricing</NavLink>
      <NavLink href="/#">Testimonials</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <NavLinkPhone href="tel:3364376394">336-437-6394</NavLinkPhone>
    </NavLinks>
  ];
  return (
    <>
      {/* <MainHeader /> */}
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <Link to="/gallery">
                <PrimaryButton css={buttonRoundedCss}>
                  {primaryButtonText}
                </PrimaryButton>
              </Link>
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image src={imageSrc} />
                {/* {imageDecoratorBlob && <ImageDecoratorBlob />} */}
                {/* <Testimonial>
                  <QuotesLeftIcon/>
                  <Quote>{testimonial.quote}</Quote>
                  <CustomerName>{testimonial.customerName}</CustomerName>
                  <CustomerCompany>{testimonial.customerCompany}</CustomerCompany>
                </Testimonial> */}
                <InnerImage1>
                  <Image src={imageSrc2} />
                </InnerImage1>
                {/* <InnerImage2>
                  <Image src={imageSrc3} />
                </InnerImage2> */}
              </ImageContainer>
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
