import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ThreeColSlider from "components/cards/ThreeColSlider";
import MainHeader from "components/headers/MainHeader";

import StaircaseImage from "../images/feature/stairs.jpg";
import DoorsImage from "../images/feature/doors-2.jpg";

const Image = tw.img`z-30 max-w-full w-96 rounded-t sm:rounded relative`;

export default () => {
  return (
    <>
        <MainHeader />
        <AnimationRevealPage>
            {/* <Image src={StaircaseImage} /> */}
            <ThreeColSlider />
            <Footer />
        </AnimationRevealPage>
    </>
  );
};
